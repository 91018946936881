@import 'src/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.monitorIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 248px;
  height: 234px;
  margin-bottom: 50px;
  background-image: url('../../../../../assets/images/empty-background.png');
  background-size: contain;
}

.monitorIcon {
  width: 50px;
  height: 50px;
}

.textContainer {
  margin-bottom: 32px;
}

.text {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: #8A8A8F;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  margin: 0 auto;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: white;
  }
}
