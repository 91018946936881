@import 'src/variables.scss';

.container {
  z-index: 2000;
  display: none;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
}

.containerActive {
  z-index: 2000;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
}

.opacityBackground{
  z-index: 2000;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #DCDCDC;
  opacity: 0.1;
  z-index: 500px;
}

.confirmWindow {
  z-index: 2000;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
  min-height: 160px;
  background-color: white;
  box-shadow: 10px 10px 5px #aaaaaa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000px;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
}

.confirmWindowScreenSizes {
  z-index: 2000;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 329px;
  background-color: white;
  box-shadow: 10px 10px 5px #aaaaaa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000px;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
}

.warningStyle{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
}

.warnBody{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex:1;
  // min-width: 161px;
  // max-width: 350px;
  padding: 20px;
  padding-bottom: 0;
}

.buttonGroup{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height:80px;
}

.buttonStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 40px;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
 }

.buttonStyleCancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: $primaryColor;
  margin-right: 10px;
 }

.messageBlock {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1 1;
  margin: 24px 24px 10px 24px;
 }

.screensWrapper {
  width: 100%;
  padding: 24px;
}

.screenElement {
  padding: 15px 16px;
  border-bottom: 1px solid #EFEFF4;
  word-wrap: break-word;
}