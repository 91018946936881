
.root {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #EFEFF4;
}


.control {
  display: flex;
  align-items: center;
  margin-top: 0px;
  min-height: 42px;
  margin-bottom: 0px;
  // border-bottom: 1px solid #EFEFF4;

  &Label {
    min-width: 102px;
    margin-right: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
}

.fontSizeInput {
  display: flex;
  align-items: center;

  .pt {
    margin-left: 10px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
}