@import 'src/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 104px;
  background: white;
  padding: 10px;
  border: 1px solid #C8C7CC;
  border-radius: 2px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  color: #8A8A8F;
  position: relative;
  transition: border-color .3s ease;

  &:hover {
    border-color: $primaryColor;

    .editIcon {
      opacity: 1;
    }
  }
}

.editIcon {
  opacity: 0;
  transition: opacity .3 ease;
  position: absolute;
  top: 5px;
  right: 5px;
  transition: opacity .3s ease;
}

.textContainer {
  display: flex;
  flex-direction: column;
  line-height: 14px;
}

.title {
  font-size: 14px;
}

.subtitle {
  font-size: 12px;
}