.container {///
  display: flex;
  display: none;
  position: relative;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
}

.containerActive {///
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 600px;



}

// .opacityBackground{
//   position: absolute;
//   top:0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   background-color: #DCDCDC;
//   opacity: 0.5;
//   z-index: 500px;
// }
