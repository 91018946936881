@import 'src/variables.scss';

.container {
  width: 100%;
  border-radius: 3px;
  position: relative;
  overflow: auto;
  // margin-bottom: 24px;

  &Bordered {
    border: 1px solid#EFEFF4;
  }
}

.empty {
  padding: 20px 0;
  margin: 0;
}

.draggingItem {
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
}