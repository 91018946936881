@import 'src/variables.scss';

.root {
  display: flex;
  flex-direction: column;
}

.header {
  padding: 24px 24px 0 24px;
  margin-bottom: 16px;
}

.back {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000;

  span {
    margin-left: 8px;
  }
}

.body {
  padding: 24px;
  padding-top: 0;
  overflow-y: auto;
}

.list {
  margin-bottom: 24px;
}

.add {
  min-width: 160px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #000;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  text-decoration: none;

  &:hover {
    color: #000;
    text-decoration: none;
  }

  &IsActive {
    border-color: $primaryColor;
  }
}
