@import 'src/variables.scss';

.inputContainer {
  display: flex;
  justify-content: space-between;
}

.controlContainer {
  flex: 1;
}

.label {
  display: block;
  padding-top: 12px;
  min-width: 42px;
  margin-right: 12px;
}

.error,
.warning {
  color: red;
}
