@import 'src/variables.scss';

.root {
  max-width: 137px;
  width: 100%;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  opacity: 1;

  &:hover {
    opacity: 1;
  }
}

.input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  padding: 8px 16px;
  position: relative;
}

.value {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  // margin-right: 20px;
}

.indicator {
  width: 24px;
  height: 24px;
  background: #E5E5E5;
  border-radius: 2px;
}

.error,
.warning {
  color: red;
}
