@import 'src/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px;
}

.tip {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-bottom: 16px;

  span {
    margin-right: 5px;
  }
}

.templateCards {
  display: flex;
  margin-bottom: 32px;

  &Item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.footer {
  display: flex;

  .btn {
    &:first-child {
      margin-right: 16px;
    }
  }
}
