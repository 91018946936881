.container {
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 24px 16px;
}

.textField {
  min-height: 40px;
  width: 100%;
  padding-left: 16px;
  border: 1px solid #EFEFF4;
  background-color: #FFFFFF;
}

.sessionForm {
  display: grid;
  grid-row-gap: 16px;
}

.timeSection {
  display: grid;
  grid-template-columns: 180px 180px;
  grid-gap: 56px;
}

.gridRow {
  display: grid;
  grid-template-columns: 80px 1fr;

  & > label {
    justify-self: start;
    align-self: center;
  }
}

.gridTheatre {
  grid-template-columns: 80px 180px;
}

.gridDay {
  grid-template-columns: 80px 295px;
}