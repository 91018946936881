@import 'src/variables.scss';

.container {/////
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 0;
}

.warning {
  display: block;
  margin-top: 10px;
  color: red;
}

.input {
  width: 100%;
  border: none;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 20px;
    font-weight: normal;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
    font-weight: normal;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 20px;
    font-weight: normal;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
    font-weight: normal;
  }
}

.playlistTemplateCard {
  margin-bottom: 40px;
}


// .FieldStyle {///////
//   flex:1 auto;
//   height: 40px;
//   background: #FFFFFF;
//   border: 1px solid #EFEFF4;
//   box-sizing: border-box;
// }

// .FieldStyleMargin {///////
//   margin-right: 5px;
// }

.labelName {//////
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 3em;
  font-size: 14px;
  color: #000000;
}

.labelItemName {//////
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
}

.renderMediaListContainer{
  display: flex;
  flex-direction: column;
  flex:1;
  // overflow-y: auto;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  // background-color: red;
}

.warnColor {/////
  display: block;
  margin-top: 10px;
  color:red;
}


.groupStyle {
  padding-bottom: 25px;
}

.groupStyleRow {//////
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  height: 65px;
}

.labelGroupStyle {//////
  padding-bottom: 0;
}

.orientationContainer {//////
  float: left;
  width: 60px;
  height: 1em;
  margin: 0 30px 0 0px;
}

.orientationRadio {//////
  float: left;
  width: 90px;
  height: 2em;
  margin: 50px 30px 0 0;
  cursor: pointer;
}

.clearFix {///////
  clear: both;
}


.contentTab {//////
  width: 50%;
  height: 54px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #AAAAAA;
  border-bottom: 1px solid #E9E9E9;
  cursor: pointer;
}
.contentTabActive {/////////
  width: 50%;
  height: 54px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #AAAAAA;
  border-top: 1px solid #E9E9E9;
  border-left: 1px solid #E9E9E9;
  border-right: 1px solid #E9E9E9;
  cursor: pointer;
}

.selectedContent {/////////
  width: 515px;
  margin-left:-15px;
  border-bottom: 1px solid #E9E9E9;
  border-left: 1px solid #E9E9E9;
  border-right: 1px solid #E9E9E9;
  border-radius: 0;
  padding: 15px 7px 7px 7px;
}
.selectedContentTab {//////
  width: 515px;
  height: auto;
  margin-bottom: 0;
  margin-left:-15px;
}

.buttonStyle {///////
  width: 130px;
  height: 40px;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
}

.buttonStyleWide {//////
  flex:1 auto;
  height: 40px;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.playlistContentStyle {///////
  display: block;
  padding: 0;
  background-color: #FBFBFB;
}

.contentItem {/////////
  display: flex;
  justify-content: left;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: $primaryColor;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #E9E9E9;
}

.playListSelect {//////
  width:100%;
  overflow-x: auto;
  max-height: 268px;
  border-radius: 3px;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.templateSquare {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #FFFFFF;
  padding-bottom: 5px;
  border: 1px solid $primaryColor;
  box-sizing: border-box;/* Select content */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 2px;

color: $primaryColor;
}

.emptySquare {
  width: 50px;
  height: 50px;
  background-color: white;
  border: $primaryColor solid 1px;
}


