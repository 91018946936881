@import 'src/variables.scss';

.container {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  position: relative;

  &IsFocused {
    .item {
      border: 1px solid #EFEFF4;
    }
  }
}

.item {
  border: 1px solid transparent;
}

.datePicker {
  margin-left: 16px;
}

.to {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 40px;
  margin: 0 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #666;
}

.trash {
  height: 40px;
  width: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: -2px;

  &:hover {
    opacity: 0.6;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}