.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputContainer {
  width: 100%;
  height: 40px;
  display: flex;
}

.input {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #000;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  padding: 10px;
  padding-right: 50px;
  position: relative;

  .icon {
    position: absolute;
    top: 6px;
    right: 10px;
  }

  &IsEmpty {
    color: #C8C7CC;
  }
}

.error {
  color: red;
}