@import 'src/variables.scss';

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.root {
  background: #fff;
  border-bottom: 1px solid #EFEFF4;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }
}

.removalSpinner {
  width: 100%;
  height: 100%;
  background-color: rgba(lightgray, .5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.container {
  display: flex;
  height: 44px;
  align-items: center;
  padding: 10px 12px;
  background: #FFFFFF;
  position: relative;

  &:hover {
    .menu {
      display: flex;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
    }
  }

  &IsEmpty {
    .playlistIcon {
      opacity: 0.5;
    }

    .name {
      color: #C8C7CC;
    }
  }
}

.empty {
  margin-right: 12px;
}

.dragHandle {
  width: calc(100% - 90px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: grab;
}

.contentIcon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.name {
  width: 0; // hack to not stretch the PlaylistItem if the name is very long
  flex: 1;
  font-size: 14px;
  line-height: normal;
  color: #6A6A6A;
  margin-right: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.children {
  padding: 15px 0;
}

.child {
  display: flex;
  align-items: center;
  padding-left: 50px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &Icon {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }

  &Name {
    font-size: 14px;
    color: #6A6A6A;
    width: 0;
    padding-right: 40px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.menu {
  display: none;
  flex-wrap: nowrap;
  transition: opacity .3s ease;

  &Button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &Delete {
      background-image: url(../../../../../../assets/images/icons/delete-icon.svg);

      &:hover {
        background-image: url(../../../../../../assets/images/icons/delete-icon-gray.svg)
      }
    }
  }
}
