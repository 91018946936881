@import 'src/variables.scss';

.container {
  min-width: 170px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow: hidden;
  transition: opacity .3s ease;
  z-index: 1000;
  position: absolute;

  opacity: 0;
  top: 12px;
  left: -100000px;

  &IsOpen {
    left: auto;
    opacity: 1;
  }
}

.item {
  // width: 100%;
  padding: 15px 27px;
  text-align: left;
  margin: 0;
  color: rgba(0, 0, 0, 1);
  border-bottom: 1px solid #EFEFF4;
  text-decoration: none !important;

  &:hover {
    color: rgba(0, 0, 0, .8);
  }

  &:last-child {
    border-bottom: 0;
  }
}
