@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.item {
  margin: 16px 0 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #EFEFF4;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  min-height: 48px;
  flex-direction: column;

  &IsEditable {
    &:hover {
      .buttons {
        display: flex;
        animation: fadeIn 0.5s;
      }
    }
  }
}

.time {
  flex: 0 0 auto;
  margin-right: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  align-self: center;
}

.sectionGroup {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  justify-content: center;
  width: 100px;
}

.name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.speakers {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #8A8A8F;
  white-space: normal;
  margin-left: 110px;
  word-break: break-all;
}

.buttons {
  display: none;
  flex: 0 0 auto;
  margin-left: 16px;
}

.row {
  display: flex;
  min-height: 29px;
}