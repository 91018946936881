@import 'src/variables.scss';

.container {
  height: 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;

  &:first-child {
    margin-bottom: 8px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  &Left {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }

  .dotsButton {
    margin-left: 16px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 14px;
  color: #000000;
}

.placeholderWrapper {
  display: flex;
  align-items: center;
}

.placeholder {
  font-size: 14px;
  color: #666666;

  &Small {
    font-size: 12px;
  }
}

.tipContainer {
  margin-left: 8px;
  flex: none;
}

.tooltip {
  max-width: 180px
}

.body {
  flex: 1;
  overflow: auto;
}

.choosePlaylistBtn {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
