@import 'src/variables.scss';

.container {
  display: flex;
  width: 100%;
  height: 62px;
  background: #FFFFFF;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
}

.sizeCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 122px;
  padding: 0 12px;
  border: 1px solid #EFEFF4;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &Icon {
    margin-bottom: 6px;
  }

  &Text {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #8A8A8F;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #8A8A8F;
  text-align: center;
  padding: 0 21px;
  border: 1px dashed #EFEFF4;
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

// styles bellow may be useful, but it's accurate

.drop_area {
  width: 100%;
  border-radius: 3px;
  clear: both;
}

.dropArea {
  display: flex;
  flex-direction: column;
}

.video_size_and_position {
  width: auto;
  height:100%;
  position: absolute;
  left: 50%;
  top:0;
  transform: translate(-50%,0);
}

.dropZoneStyle1 {
  margin: 10px;
  background-color: $primaryColor;
}

.containerDefaultDropArea{
  display: flex;
  height: 54px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #C8C7CC;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.isOverOnDragDefaultDropArea{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px;
  height: 54px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.uploadImage {
  margin-bottom: 18px;
}

.defaultDropArea {
  width: 103px;
  height: 97px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.dropTextStyle {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 10
}

.coloredText {
  color: $primaryColor;
}