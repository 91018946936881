.sessionTheatreList {
  margin: 0 0 24px 0;
  padding: 0 15px;
  overflow: auto;
  padding: 32px 15px 0 15px;
}

.sessionDayItem {
  width: 100%;
  min-width: 511px;
  margin-top: 32px;
}

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.menu {
  opacity: 0;
  top: -7px;
  left: -100000px;
  right: auto;
  font-size: 14px;

  &IsOpen {
    left: auto;
    right: 5px;
    opacity: 1;
  }
}
