.containerActive {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20100;
  height: 100%;
  width: 100%;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  // background-color: #000;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
  user-select: none;
}

.containerHide {
  display: none;
}

.mainContainer {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #000;
  max-width: 1220px;
}

.closeButton {
  background: white;
  border: none;
  outline: none;
  margin: 30px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}