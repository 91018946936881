@import 'src/variables.scss';

.root {
  border-bottom: 1px solid #EFEFF4;

  &:last-child {
    border-bottom: 0;
  }
}

.inner {
  display: flex;
  align-items: center;
  height: 50px;
  position: relative;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

    .addBtn {
      opacity: 1;
      display: flex;
    }
  }

  &IsEmpty {
    .playlistIcon {
      opacity: 0.5;
    }

    .name {
      color: #C8C7CC;
    }
  }

  &IsOpen {
    .name {
      font-weight: 600;
      color: #000000;
    }
  }
}

.toggleArea {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.addBtn {
  flex: none;
  display: none;
  align-items: center;
  color: $primaryColor;
  font-size: 14px;
  // top: 50%;
  // position: absolute;
  // right: 56px;
  // transform: translateY(-50%);
  // opacity: 0;
  transition: opacity .3s ease;
  margin-right: 32px;
}

.name {
  // width: 0;
  flex: 1;
  display: flex;
  align-items: center;
  color: #6A6A6A;
  padding-right: 10px;

  &Text {
    width: 0;
    flex: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .empty {
    margin-right: 12px;
    color: #C8C7CC;
  }
}

.checkbox {
  flex: none;
  height: 20px;
  width: 20px;
  margin: 0 28px 0 26px;
  border-radius: 50%;
  border: 2px solid #C8C7CC;
  background-color: white;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid $primaryColor;
    border-width: 0 3px 3px 0;
    position: absolute;
    transform: rotate(45deg);
  }

  &IsSelected {
    background-color: #fff;
    border: 2px solid $primaryColor;
    color: $primaryColor;

    &:after {
      display: block;
    }
  }
}

.arrow {
  // margin-left: 16px;
  // position: absolute;
  // top: 50%;
  // right: 10px;
  // transform: translateY(-50%);
}

.pusher {
  width: 16px;
  height: 16px;
  visibility: hidden;
}

.children {
  padding: 15px 0;
}

.child {
  display: flex;
  align-items: center;
  padding-left: 118px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &Icon {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }

  &Name {
    font-size: 14px;
    color: #6A6A6A;
    width: 0;
    padding-right: 40px;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
