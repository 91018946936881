@import 'src/variables.scss';

.sessionContainer {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: calc(588px - 24px);
  max-width: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  color: #000;
}

.wrapIcon {
  cursor: pointer;
}

.bntGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.saveBtn {
  width: 180px;
  margin-right: 16px;
  margin-left: auto;
}