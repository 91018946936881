@import 'src/variables.scss';

.container {
  padding: 24px;
  display: flex;
}

.form {
  width: 558px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  padding: 24px;

  .contentWrapper {
    border-radius: 3px;
  }
}

.fieldArray {
  max-width: 396px;

  &IsHidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding:0 !important;
    border:0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
}

.inputFocused {
  display: block;
  width: 100%;
  height: 40px;
  padding: 11px 5px 5px 6px;
  border: 0px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  top: -3px;
  position: relative;
}

.inputNotFocused {
  display: block;
  width: 100%;
  height: 40px;
  padding: 5px 5px 5px 5px;
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.inputNameContainer {
  // margin-right: 24px;

  &.isUrl {
    max-width: 500px!important;
  }
}

.clockBtn {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  min-width: 120px;
  padding: 0 10px;
  padding-right: 22px;
  background: #F6F6F6;
  border-radius: 40px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  background-color: #F6F6F6;
  position: relative;
  margin-bottom: 32px;

  &IsActive {
    color: #EC812B;
    background-color: rgba(#EC812B, 0.1);
  }

  span {
    margin-left: 10px;
  }
}

.contentGroupSizesContainer {
  margin-bottom: 24px;
}

.contentGroupSize {
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  border-radius: 2px;
}

.cancelBtn {
  margin-left: auto;
  padding-right: 20px;
  background-color: transparent;
}

.replaceItemSpinnerContainer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .cancelBtn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10;
  }
}

.formGroupBlock {
  border: 1px solid #EFEFF4;
  margin-bottom: 10px;
  width: 100%;
}

.tabStyles{
  display: flex;
  flex-direction: row;
}

.formGroupWrapper {
  display: flex;
  justify-content: space-between;
}

.formBlockStyleDuration {
  margin-top: 20px;
}
.formBlockStyleSize {
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: red;
}

.buttonStyleLeft {
  width: 50%;
  margin: 0;
  height: 50px;
  background: rgba(164, 164, 164, 0.05);
  color: #C6C6C6;
  border-radius: 3px 0px 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  cursor: pointer;
}

.buttonStyleLeftActive {
  width: 50%;
  margin: 0;
  height: 50px;
  color: #000;
  background: #fff;
  border-radius: 3px 0px 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;
}

.buttonStyleRight {
  width: 50%;
  margin: 0px;
  height: 50px;
  background: rgba(164, 164, 164, 0.05);
  color: #C6C6C6;
  border-radius: 0px 3px 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;
}

.buttonStyleRightActive {
  width: 50%;
  margin: 0px;
  height: 50px;
  background: #fff;
  color:black;
  border-radius: 0px 3px 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;
}

.buttonStyle {
  width: 130px;
  height: 40px;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  margin-left: 0;
  color: white;
}
.buttonStyleCancel {
  width: 103px;
  height: 40px;
  background-color:#fff;
  color:$primaryColor;
  border-color: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  margin-left: 0;
}

.labelButtonStyleCancelActive {
  width: 156px;
  height: 40px;
  background-color:#fff;
  color:$primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primaryColor;
  border-radius: 3px;
  cursor: pointer;
}

.labelButtonStyleCancel {
  width: 156px;
  height: 40px;
  background-color:#fff;
  color:#C8C7CC;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #C8C7CC;
  border-radius: 3px;
  cursor: pointer;
}

.optionsStyles {
  width: 80px;
  height: 40px;
  padding: 5px 5px 5px 5px;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  overflow: hidden;
}

.optionsStylesToggled {
  width: 80px;
  height: 40px;
  padding: 5px 5px 5px 5px;
  border: 1px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
}

.optionsStyles:focus {
  border: 1px solid $primaryColor;
  padding: 5px 5px 5px 5px;
}

.labelDuration {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
}

.imgSize {
  width: 24px;
  height: auto;
}

.groupStyleRow {
  display: flex;
  flex-direction: row;
}

.inputWrapper {
  flex: 1;
}
.inputWrapperDuration {
  width: 90px;
}

.dataFileWrapper {
  position: relative;
  border-bottom: 1px solid #EFEFF4;
  height: 50px;
  display: flex;
}

.progressWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.progressBar{
  position: absolute;
  bottom:0;
  left:0;
  width: 100%;
}

.dataFileContainer {
  min-width: 210px;
  line-height: 50px;
  text-align: left;
}

.dataBlock {
  justify-content: space-between;
  line-height: 50px;
  text-align: left;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  & img {
    width: 100%;
  }
}

.dataBlockUpload {
  justify-content: space-between;
  line-height: 50px;
  text-align: left;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 15px;

  & img {
    width: 100%;
  }
}

.nameBlock {
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  line-height: 50px;
  max-width: 150px;
  text-align: left;
  margin-right: 16px;
  text-overflow: ellipsis;
}

.nameBlockUpload {
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  line-height: 50px;
  max-width: 250px;
  text-align: left;
  margin-right: 16px;
  text-overflow: ellipsis;
}

.childDropZone {
  flex: 1;
  margin-bottom: 24px;
}

.optionsStylesToggled {
  width: 80px;
  height: 40px;
  padding: 5px;
  border: 1px solid #fff;
  box-sizing: border-box;
}

.dropZoneWrapper {
  flex: 1;
  margin: 24px 17px 0px 17px;
}

.textBlock {
  display: flex;
  flex-direction: row;
  line-height: 40px;
}
.textBlock span {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  padding: 0 5px;
  color: #000000;
}

.inputStyle {
  position: relative;
}

.unitMeasure {
  position: absolute;
}

.replaceStyle {
  color: $primaryColor;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
}

.editor {
  border: 1px solid #EFEFF4;
  margin-bottom: 16px;
}

.buttonHtmlGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & button {
    margin: 0;
  }
}

.wrapClickablePlace {
  display: flex;
  width: calc(100% - 95px);
  padding-left: 15px;
}

.fullscreenToggler {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 34px;

  &Label {
    color: #000000;
    line-height: 20px;
    font-size: 14px;
    margin-right: 10px;
  }
}

.previewContent {
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
}

.previewButton {
  margin-right: 10px;
}