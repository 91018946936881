.container {
  display: flex;
  justify-content: space-between;
}

.input {
  flex: 1;
  display: flex;
  align-items: center;
  width: 80px;
  height: 40px;
  color: #000;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  padding: 10px;
  text-align: center;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    font-size: 14px;
    font-weight: normal;
    color: gainsboro;
  }
}

.label {
  display: block;
  padding-top: 12px;
  min-width: 42px;
  margin-right: 12px;
}

.controlContainer {
  flex: 1;
}

.error {
  color: red;
}