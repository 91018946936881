.root {
  display: grid;
  row-gap: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.field {
  display: flex;
}

.fieldLabel {
  min-width: 104px;
  padding-top: 10px;
  margin-right: 28px;
}

.fieldInput {
  border: 0;
  // margin-left: 28px;
}