@import 'src/variables.scss';

.modalContainer {
  z-index: 10000000000000; // because froala editor full screen modal z-index is big
}

.modal {
  max-height: 100%;
  max-width: 505px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadNewImage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  height: 40px;
  margin: 0;
  border-radius: 3px;
  color:white;
  mix-blend-mode: normal;
  border: 1px solid $primaryColor;
  background-color: $primaryColor;
}


.title {
  line-height: 31px;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  color: #666;
  margin: 0;
  padding: 0;
}

.list {
  flex: 1;
  margin: 24px 0;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  overflow: auto;
}

.item {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding: 14px 20px;
  border-bottom: 1px solid #EFEFF4;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-color: transparent;
  }

  &IsSelected {
    box-shadow: 0px 0px 0px 1px $primaryColor;
    border-bottom-color: transparent;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
}
