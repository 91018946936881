@import 'src/variables.scss';

.container {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 24px;
  background-color: white;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;
}

.templateCard {
  margin-bottom: 24px;
}

.spinner {
  z-index: 100;
}

.playlistContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #EFEFF4;
  border-radius: 3px;

  .inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 0;
  }

  .header {
    flex: none;
    display: flex;
    height: 86px;
    margin: 0 16px;
    border-bottom: 1px solid #EFEFF4;
    padding: 14px 0;

    &Left {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #8A8A8F;
    }

    .now {
      padding-bottom: 8px;
      border-bottom: 1px solid #EFEFF4;
    }

    .next {
      padding-top: 8px;
    }

    &Right {
      flex: 1;
      display: flex;
      max-width: 230px;
      align-items: center;
      justify-content: center;
      padding-left: 50px;
    }
  }

  .body {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .choosePlaylist {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &Inner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .tooltip {
      width: 200px;
    }
  }

  .playlist {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 0;

    &Header {
      display: flex;
      align-items: center;
      padding: 16px;
    }

    &Dots {
      margin-left: 16px;
    }

    &Name {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin-right: auto;
    }

    &Contents {
      flex: 1;
      height: 0;
    }
  }

  .bottomBar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #666666;
    border-top: 1px solid #EFEFF4;
  }
}

.createProgramme {
  color: $primaryColor;
  text-decoration: underline;

  &:hover {
    color: $primaryColor;
    text-decoration: none;
  }
}
