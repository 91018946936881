.root {
  color: #000;
  border: 1px solid #EFEFF4;
  border-radius: 3px;

  & + & {
    margin-top: 16px;
  }

  &IsOpen {
    .header {
      padding-bottom: 16px;
    }

    padding-bottom: 26px;
  }
}

.header {
  display: flex;
  padding: 20px 24px;
}

.settings {
  margin-left: auto;
}

.delete {
  margin-left: 16px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.programmes {
  padding: 0 20px;

  &EmptyText {
    text-align: center;
  }
}
