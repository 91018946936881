@import 'src/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;

  &IsOpen {
    &:hover {
      box-shadow: none;
    }

    .actionsMenu {
      left: auto;
      top: 60px;
      opacity: 1;
    }
  }
}

.text {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: white;
}

.buttonPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 35px;
  margin: 0 auto;
  // border: 2px solid;
  // border-color: $primaryColor;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: white;
  text-decoration: none;
  border-right: 1px solid #F4F4F4;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.buttonCopy {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  height: 35px;
  margin: 0 auto;
  margin-right: 20px;
  // border: 2px solid;
  // border-color: $primaryColor;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: white;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.buttonDisabled {
  cursor: default;
  background-color: #AAAAAA;
  pointer-events: none;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-left: 0px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  // left: 50%;
  margin-left: -100px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.actionsMenu {
  opacity: 0;
  top: 12px;
  left: -100000px;
  right: auto;
}