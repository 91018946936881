@import 'src/variables.scss';

.container {
  z-index: 2000;
  display: none;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
}

.containerActive {
  z-index: 2000;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
}

.opacityBackground{
  z-index: 2000;
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #DCDCDC;
  opacity: 0.1;
  z-index: 500px;
}

.confirmWindow {
  z-index: 2000;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  width: 329px;
  background-color: white;
  box-shadow: 10px 10px 5px #aaaaaa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000px;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  padding: 32px 24px;
}

.warningStyle{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
  width: 100%;
  word-wrap: break-word;
}

.warnBody{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex:1;
  width: 100%;
  flex-direction: column;
}

.buttonGroup{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
}

.buttonStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 40px;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
 }

.buttonStyleCancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: $primaryColor;
  margin-right: 10px;
 }

.playlistsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24px;
  max-height: 140px;
  overflow-y: scroll;
}

.playlistName {
  padding: 15px 16px;
  border-bottom: 1px solid #EFEFF4;
}

.hiddenContents {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-height: 70px;
  overflow: auto;
  margin: 0 auto;
  max-width: 200px;
  word-wrap: break-word;
  overflow-y: scroll;
}

.hideMessage {
  width: 100%;
  text-align: center;

  & button {
    margin: 0;
  }
}

.hiddenContent {
  width: 100%;
  word-wrap: break-word;
  white-space: nowrap;
}

.deletingPlaylistContainer {
  .containerActive {
    z-index: 2000;
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .opacityBackground {
    z-index: 2000;
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #DCDCDC;
    opacity: 0.1;
    z-index: 500px;
  }

  .confirmWindowScreenSizes {
    z-index: 2000;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 329px;
    background-color: white;
    box-shadow: 10px 10px 5px #aaaaaa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000px;
    box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
    border-radius: 3px;
  }

  .warningStyle{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
  }

  .buttonGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height:80px;
    margin-top: 0;
  }

  .buttonStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 133px;
    height: 40px;
    background: $primaryColor;
    mix-blend-mode: normal;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    color: #FFFFFF;
  }

  .messageBlock {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1 1;
    margin: 24px 24px 10px 24px;
  }
}