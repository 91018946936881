@import 'src/variables.scss';

.root {
  width: 100%;
  display: flex;
  padding: 0 8px;
  height: 40px;
  align-items: center;
  border: 1px solid #EFEFF4;
  text-decoration: none;
  cursor: pointer;
}

.insertImage {
  margin-left: auto;
  margin-right: 8px;
}

.selectedImage {
  flex: 1;
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  height: 30px;
  background: #EFEFF4;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;

  & .text {
    flex: 1;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .delete {
    width: 16px;
    height: 16px;
    padding: 0;
    border: 0;
    margin-left: 10px;
    background: transparent;
  }
}
