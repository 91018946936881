@import 'src/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 108px;
  margin: 8px;
  background-color: white;
  border: 1px solid #EFEFF4;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
  }

  &IsActive {
    border-color: $primaryColor;
  }
}

.icon {
  width: 34px;
  height: 34px;
  margin-bottom: 16px;
  background-image: url(../../../../../assets/images/icons/orientation-portrait.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #8A8A8F;
}
