.rc-slider {
  height: 0;
  padding: 0;
  pointer-events: none;
}

.rc-slider-handle {
  pointer-events: all;
  margin: 0;
  width: 1px;
  height: 40px;
  border-radius: 0;
  background: #EC812B;
  border: none;
  position: absolute;
  top: auto;
  bottom: 0;
  cursor: default;
  // top: -30px;

  &:active,
  &:focus {
    border: none;
    box-shadow: none;
  }

  &::before {
    content: '';
    width: 15px;
    height: 15px;
    cursor: grab;
    border-radius: 50%;
    background: #EC812B;
    position: absolute;
    top: 0;
    transform: translateX(-7px);
  }
}

.rc-slider-track {
  display: none;
}
