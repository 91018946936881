.container {
  border-bottom: 1px solid #EFEFF4;
  margin: 0 24px;
  padding-bottom: 24px;
}

.customLabel {
  width: 70px;
}

.speakerAddForm {
  height: 0;
  overflow: hidden;
}

.wrapField:not(:last-child) {
  margin-bottom: 16px;
}

.wrapBtn {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.openedForm {
  justify-content: flex-end;
}

.formOpen {
  // display: grid;
  // grid-row-gap: 16px;
  // margin-bottom: 24px;
  height: auto;
}

.cancelBtn {
  margin-right: 16px;
}