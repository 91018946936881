@import 'src/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  background: none;

  &:hover {
    opacity: .6;
  }
}

.icon {
  width: 100%;
  height: 100%;
  // fill: #8A8A8F;
  // width: 100%;
  // height: 100%;
  // display: inline-block;
  // user-select: none;
  // flex-shrink: 0;
  // transition: background-color .3s ease;
  // border-radius: 50%;

  // &:hover {
  //   background-color: rgba(211, 211, 211, 0.253);
  // }
}
