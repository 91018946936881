.description__froala {
  .fr-toolbar {
    border-top: none!important;
  }


  .fr-box {
    .fr-wrapper {
      box-shadow: none!important;
      border: 1px solid #EFEFF4!important;
    }
  }

}