.container {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
}

.rightSide {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 80px;
  flex: 1
}

.leftSide {
  height: 100%;
  width: 100%;
  flex: 2 1;
  background-image: url('../../../../../assets/images/login-page.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.label {
  margin-top: 60px;
  margin-bottom: auto;
}

.footer {
  color: #AAAAAA;
  font-size: 13px;
  margin-top: auto;
  margin-bottom: 20px;
  text-align: center;
}