@import "src/variables";

.container {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  padding-right: 125px;
  min-height: 60px;
  color: #000;
  background-color: white;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  margin-bottom: 8px;
  text-decoration: none;

  &:hover {
    color: #000;
    text-decoration: none;
  }

  // &:last-child {
  //   margin-bottom: 0;
  // }

  &IsActive {
    border-color: $primaryColor;
  }

  &IsEmpty {
    color: #8A8A8F;
    &:hover {
      color: #8A8A8F;
    }

    .name .empty {
      color: '#C8C7CC';
    }
  }

  &IsShowAll {
    max-height: 1000px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.empty {
  font-size: 10px;
  line-height: 14px;
}

.name {
  font-size: 14px;
  line-height: 20px;

  .empty {
    margin-right: 6px;
  }
}

.dotsButton {
  margin-left: 15px;
}

.right {
  height: 58px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.icon {
  margin-right: 15px;
}

.menu {
  opacity: 0;
  top: 12px;
  left: -100000px;
  right: auto;

  &IsOpen {
    left: auto;
    right: 12px;
    opacity: 1;
  }
}

.ownerScreensList {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  &Item {
    flex-shrink: none;
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 10px;
    line-height: 14px;
    max-width: 82px;

    padding-right: 8px;
    margin: 4px;
    position: relative;

    & .text {
      max-width: 82px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &::after {
      content: '';
      flex-shrink: none;
      display: block;
      width: 2px;
      height: 2px;
      background: #8A8A8F;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &.last {
      padding-left: 0;

      &::after {
        display: none;
      }
    }
  }
}

.showAll {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
}
