.container {
  margin-top: 16px;
}

.subTitle {
  margin-bottom: 11px;
}

.codeMirrorWrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 3;
  overflow: hidden;
  height: calc(100% - 38px);
  border: 1px solid #EFEFF4;
}

.codeMirrorFullScreen {
  margin: 0!important;
  position: fixed;
  top: 38px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483631!important;
  width: auto!important;
  border: none!important;
}

.wrapFroala {
  position: relative;
}