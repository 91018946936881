.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  border-bottom: 1px solid #EFEFF4;
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  }

  &:last-child {
    border-bottom: 0;
  }
}

.content {
  flex: 1;
  display: flex;
  padding-right: 20px;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
}

.name {
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  color: #6A6A6A;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.progressBar {
  height: 2px;
  border-radius: 1px;
  width: 100%;
}

.iconPosition {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 20px;
  margin-top: -2px;
}

