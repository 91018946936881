@import 'src/variables.scss';

.container {
  display: none;
  width: 100%;
  height: 100%;
  padding: 40px 15px;
  overflow: auto;
  background-color: rgba(#DCDCDC, 0.5);
  position: fixed;
  top:0;
  left: 0;
  z-index: 9000;

  &IsOpen {
    display: flex;
  }

  &ScrollBody {
    align-items: flex-start;
  }

  &ScrollModal {
    align-items: center;
    justify-content: center;
  }
}

.underlay {
  width: 100%;
  height: 100%;
  // background-color: #DCDCDC;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.modal {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 24px;
  margin: 0 auto;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  z-index: 1000;
}

.title {
  line-height: 31px;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  margin: 0;
  padding: 0;
}