.header {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #EFEFF4;
}

.api {
  margin-bottom: 24px;

  &Select {
    max-width: 236px;
    margin-bottom: 16px;
  }

  &Options {
    padding: 16px;
    border: 1px solid #EFEFF4;
    border-radius: 3px;

    &Title {
      font-size: 14px;
      line-height: 20px;
      color: #8A8A8F;
      margin-bottom: 12px;
    }
  }
}

.options {
  padding: 24px 0;
  margin-bottom: 24px;
  border: 1px solid #EFEFF4;
  border-left: 0;
  border-right: 0;
}

.history {
  &Title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 16px;
  }

  &List {
    overflow-y: auto;
    max-height: 150px;
    border: 1px solid #EFEFF4;
    border-radius: 3px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.dateField {
  position: relative;

  &Input {
    border: 1px solid #EFEFF4;
  }
}
