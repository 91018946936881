
.root {
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border: 1px solid #EFEFF4;
  border-radius: 3px;

  & + & {
    margin-top: 8px;
  }

  &IsSyncing {
    .syncBtn {
      color: #EC812B;
    }
  }

  &IsActive {
    border: 1px solid #EC812B;
  }
}

.syncBtn {
  color: #C8C7CC;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;

  span {
    margin-left: 8px;
  }
}
