@import 'src/variables.scss';

.root {
  padding: 24px;
  border: 1px solid #EFEFF4;
  border-top: 0;
}

.addWebSiteBtn {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 20px;
  border: 1px solid #C8C7CC;
  box-sizing: border-box;
  border-radius: 3px;
}

.form {
  // padding: 15px;
}

.title {
  margin-bottom: 24px;
}

.inputWrapper {
  margin-bottom: 14px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
