@import 'src/variables.scss';

.body {
  padding: 24px 16px;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  margin: 24px 0;
}

.control {
  display: flex;
  align-items: center;
  min-height: 42px;
  margin-bottom: 16px;

  &Label {
    min-width: 102px;
    margin-right: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
}
