@import 'src/variables.scss';

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  // padding: 10px;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;

  &IsHidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding:0 !important;
    border:0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
}

.modal {
  // width: 90%;
  min-width: 0;
  margin: auto;
  position: relative;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}
