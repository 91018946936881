@import 'src/variables.scss';

.container {
  height: 100%;
  width: 100%;
  position: relative;
  outline: none;
}

.dropZoneOverlay {
  display: none;
  width: 100%;
  height: 100%;

  border: 1px dashed #EC812B;
  background: rgba(236, 129, 43, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 3px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  &IsVisible {
    display: block;
  }
}