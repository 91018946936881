@import "src/variables.scss";

.inputStyle {
  position: relative;
  z-index: -10;
}

.unitMeasure {
  position: absolute;
  bottom:12px;
  margin-left: -40px;
}

.inputNotFocus {
  display: block;
  width: 80px;
  height: 40px;
  padding: 11px 5px 5px 6px;
  border: 0px;
  box-sizing: border-box;
  // border: 1px solid #EFEFF4;
  overflow: hidden;
  color: #000;
}

.inputOnFocus {
  color: #000;
  display: block;
  width: 80px;
  height: 40px;
  padding: 5px 5px 5px 5px;
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  overflow: hidden;
}