@import 'src/variables.scss';

.drop_area {
  width: 100%;
  border-radius: 3px;
  clear: both;
}

.dropArea {
  display: flex;
  flex-direction: column;
}

.video_size_and_position {
  width: auto;
  height:100%;
  position: absolute;
  left: 50%;
  top:0;
  transform: translate(-50%,0);
}

.dropZoneStyle {
  margin: 10px;
  background-color: $primaryColor;
}

.containerDefaultDropArea{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #C8C7CC;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 18px;
//   background-color: red;
}

.isOverOnDragDefaultDropArea{
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px dashed #C8C7CC;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 18px;
  // background-color: red;
}

.uploadImage {
  max-width: 85px;
  margin-bottom: 18px;
}

.defaultDropArea {
  width: 103px;
  height: 97px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.dropTextStyle {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 10
}

.coloredText {
  color: $primaryColor;
}

.styleForDragZone {
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  font-size: 16px;
  text-align: center;

  color: #000000;
}