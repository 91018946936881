@import 'src/variables.scss';

.container {
  // margin-top: 16px;
  // padding: 24px;
}

.htmlEditor {
  margin: 10px;
}

.title {
  text-align: center;
  cursor: default;
  margin-bottom: 12px;
}

.editor {
  border: 1px solid #EFEFF4;
  margin-bottom: 16px;
}

.fieldName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 130px);
}

.labelName{
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.inputName {
  border: 1px solid #EFEFF4;
  padding: 10px;
}

.btnGroup {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
}

.openButton {
  cursor: pointer;
  color: $primaryColor;
}

.template {
  display: flex;
  justify-content: flex-end;
}

.controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

.imageControls {
  display: none;
  align-items: flex-start;

  &Control {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #EFEFF4;
    padding: 8px;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }

    .icon {
      margin: 0!important;
    }
  }

  &IsShow {
    display: flex;
  }
}

.selectContainer {
  position: relative;
  z-index: 2010;
  width: 100%;
  max-width: 162px;
  // width: 100%;
  margin-right: 24px;
}

.select {
  color: red;
}

.previewBtn {
  margin: 0;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 107px;
  background: #F6F6F6;
  padding: 8px 12px;
  border-radius: 50px;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.wrapFroala {
  display: block;
  position: relative;
  width: 800px;
  max-height: 575px;
  overflow: hidden;

  p {
    text-align: left;
  }
}

.fullscreenBtn {
  opacity: 0;
  display: flex;
  align-items: center;
  // height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(#000, 1);
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  transition: opacity .3s ease;
  position: absolute;
  right: 16px;
  top: 54px;
  z-index: 2005;

  &:hover {
    opacity: 1;
    color: rgba(#000, 0.8);

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  &IsVisible {
    opacity: 1;

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }
}

.nameFieldGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.wrapScreenSizes {
  width: 800px;
  max-width: 800px;
  position: relative;
  z-index: 9;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 15px;
  padding: 0 16px;
}

.screenSizeItem {
  flex: 0 0 135px;
  text-align: center;
  padding: 10px 0;
  border: 1px solid $primaryColor;
  margin: 0;
  background: #FFFFFF;
  border-radius: 3px;
  color: $primaryColor;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.noneSelect {
  border-color: #C8C7CC;
  color: #C8C7CC;
}

.wrapSvgIco {
  height: 25px;
  width: 25px;
  display: flex;
  margin-right: 3px;
}

.innerScreenSizes {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  width: 100%;
  box-sizing: content-box;
  padding-bottom: 16px;
  scrollbar-width: none;

}

.innerScreenSizes::-webkit-scrollbar {
  display: none;
}

.codeMirrorWrapper {
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 2007;
  width: 100%;
  height: calc(100% - 38px);
}

.visibilityHidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 0px !important;
  width: 0px !important;
  overflow: hidden;
}

.cmFullScreen {
  position: fixed;
  z-index: 9999999999999999999;
}
