.sessionDayItem {
  width: 100%;
}

.title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.menu {
  opacity: 0;
  top: -14px;
  left: -100000px;
  right: auto;
  font-size: 14px;

  &IsOpen {
    left: auto;
    right: 5px;
    opacity: 1;
  }
}
