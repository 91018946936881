.root {
  width: 100%;
  height: 244px;
  background: black;
  overflow: hidden;
  position: relative;
}

.button {
  width: 85px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background-color: white;
  font-size: 14px;
  color: #000;
  position: absolute;
  top: 8px;
  right: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.spinner {
  z-index: 10;
  opacity: 1;
}

.spinnerUnderlay {
  background: #f6f6f6;
  opacity: 1;
}
