@import 'src/variables.scss';

.title {
  line-height: 31px;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  margin: 0;
  // margin-bottom: 4px;
  padding: 0;
}

// .subtitle {
//   font-size: 14px;
//   line-height: 20px;
//   color: #666;
//   margin: 0;
//   padding: 0;
// }

.notFoundPlaylists {
  margin: 0;
  padding: 20px 0;
}

.modal {
  width: 100%;
  max-width: 400px;
  min-width: auto;
  max-height: 100%;
}

.list {
  overflow-y: auto;
  margin: 24px auto;
  width: 99%;
  padding: 2px;

  &Inner {
    border: 1px solid #EFEFF4;
    border-radius: 3px;
  }
}

.item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  padding: 14px 20px;
  border-bottom: 1px solid #EFEFF4;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-color: transparent;
  }

  &IsSelected {
    color: #000;
    box-shadow: 0px 0px 0px 1px $primaryColor;
    border-bottom-color: transparent;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
}
