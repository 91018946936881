@import 'src/variables.scss';

.root {
  width: 100%;
  max-width: 400px;
  display: flex;
}

.btn {
  font-size: 14px;
  color: #EC812B;
  padding: 0;
  border: 0;
  border-bottom: 1px solid transparent;
  margin: 0;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid #EC812B;
  }
}

.selectedImage {
  flex: 1;
  margin-right: 4px;
  display: inline-flex;
  align-items: center;

  & .text {
    flex: 1;
    width: 0;
    font-size: 14px;
    color: #8A8A8F;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .controls {
    display: flex;
    align-items: center;
  }
}

.trash {
  margin-left: 6px;
  margin-right: 24px;
}
