@import 'src/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 104px;
  margin: 0;
  border: 1px solid #EC812B;
  border-radius: 2px;
}

.text {
  margin-top: 17px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #EC812B;
}
