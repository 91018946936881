.menu {
  display: block;
  flex-wrap: nowrap;
  transition: opacity .3s ease;

  &Button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &Delete {
      background-image: url(../../../../../assets/images/icons/delete-icon.svg);

      &:hover {
        background-image: url(../../../../../assets/images/icons/delete-icon-gray.svg);
      }
    }

    &Edit {
      background-image: url(../../../../../assets/images/icons/edit-dark.svg);

      &:hover {
        background-image: url(../../../../../assets/images/icons/edit-gray.svg);
      }
    }

    &:first-child {
      margin-right: 16px;
    }
  }
}