@import 'src/variables.scss';

.container {
  width: 170px;
  display: flex;
  align-items: center;
  padding: 8px 42px 8px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #000000;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  // margin-bottom: 16px;
  text-decoration: none;

  &:hover {
    color: #000;
    text-decoration: none;
  }

  &IsActive {
    border-color: $primaryColor;
  }
}
