@import 'src/variables.scss';

.container {
  display: flex;
  min-height: 46px;
  align-items: center;
  padding: 10px 12px;
  background: #FFFFFF;
  border: 1px solid #C8C7CC;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 24px;
  position: relative;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #000000;
  margin-right: auto;
}
