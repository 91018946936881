@import "src/variables";

.menu {
  background-color: white;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  margin-top: 2px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(#666666, 1);
  background-color: white;
  border-bottom: 1px solid #EFEFF4;
  cursor: pointer;

  &:hover {
    color: rgba(#666666, 0.8);
  }

  &:first-child {
    // padding-top: 8px;
  }

  &:last-child {
    // padding-bottom: 8px;
    border-bottom: 0;
  }

  &IsSelected {
    // display: none;
    background-color: #F6F6F6;
  }
}

.dropdownIndicator {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  transform: rotate(180deg);
}

.singleValue {
  color: hsl(0,0%,20%);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
