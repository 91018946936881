@import 'src/variables.scss';

.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  border: 0;
}

.closeBtn {
  width: 25px;
  height: 25px;
  background-image: url('../../../../../assets/images/icons/close.svg');
  background-size: contain;
  background-color: transparent;
  position: absolute;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  // -webkit-flex: 1 1 auto;
  // flex: 1 1 auto;
  word-wrap: break-word;
  margin-bottom: 30px;
  margin-right: 24px;
}

.subheader {
  font-size: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeRange {
  display: flex;
  align-items: center;
}

.root {
  border-bottom: 1px solid #EFEFF4;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }
}

.itemsContainersWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.itemsContainer {
  height: 0;
  flex: 1;
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  position: relative;
  overflow-y: auto;
  // max-height: 550px;
  // margin-bottom: 24px;

  &Bordered {
    border: 1px solid#EFEFF4;
  }

  & + .itemsContainer {
    &Bordered {
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.noContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #8A8A8F;
}

.container {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background: #FFFFFF;
  &IsFocused {
    .item {
      border: 1px solid #EFEFF4;
      // box-shadow: 0 0 1px 0 #EFEFF4;
    }
  }
}

.contentIcon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.name {
  width: 0; // hack to not stretch if the name is very long
  flex: 1;
  font-size: 14px;
  line-height: normal;
  color: #6A6A6A;
  margin-right: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.duration {
  font-size: 14px;
  line-height: 20px;
  color: #A0A0A0;
  margin: 0 16px;
}

// .container {
//   width: 100%;
//   display: flex;
//   margin-bottom: 24px;
//   position: relative;

//   &IsFocused {
//     .item {
//       border: 1px solid #EFEFF4;
//     }
//   }
// }

.item {
  border: 1px solid transparent;
  // width: 60px;
}

.separator {
  margin: 0 6px;
}

.clearBtn {
  color: $primaryColor;
  cursor: pointer;
  margin-right: 6px;
}

.dateField {
  position: relative;
  margin-left: 10px;
}