@import 'src/variables.scss';

.programmeItem {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  min-height: 48px;
  color: #000;
  background-color: white;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  margin-bottom: 8px;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.content {
  margin-right: auto;
  width: calc(100% - 30px);
  display: flex;
}

.name {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  width: 0;
}

.menu {
  opacity: 0;
  top: 12px;
  left: -100000px;
  right: auto;

  &IsOpen {
    left: auto;
    right: 12px;
    opacity: 1;
  }
}

.isSelected {
  border: 1px solid $primaryColor;
}