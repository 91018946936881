@import 'src/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 160px;
  height: 108px;
  padding: 16px 12px;
  margin: 8px;
  background-color: white;
  border: 1px solid #EFEFF4;
  border-radius: 4px;
  position: relative;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);

    .desktopStatus {
      opacity: 1;
    }
  }

  &IsActive {
    border-color: $primaryColor;
  }

  &IsOpen {
    &:hover {
      box-shadow: none;
    }

    .actionsMenu {
      left: auto;
      right: -1px;
      opacity: 1;
    }
  }
}

.dotsButton {
  margin-right: -3px;
}

.header {
  display: flex;
  align-items: center;
}

.icon {
  width: 34px;
  height: 34px;
  margin-bottom: 16px;
  background-image: url(../../../../../assets/images/icons/orientation-landscape.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.desktopStatusArea {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.desktopStatus {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: pink;
  transition: opacity .3s ease;
  margin-left: auto;

  &Offline {
    background-color: #C8C7CC;
  }

  &Online {
    background-color: #6FCF97;
  }

  &Download {
    background-color: #F2C94C;
  }
}

.tooltip {
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
}

.actionsMenu {
  opacity: 0;
  top: 12px;
  left: -100000px;
  right: auto;
}
