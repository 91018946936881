@import 'src/variables.scss';

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.root {
  border-bottom: 1px solid #EFEFF4;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    .menu {
      display: flex;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
    }

    .duration {
      display: none;
    }
    .clockIcon {
      display: none;
    }
  }
}

.container {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background: #FFFFFF;
}

.dragHandle {
  width: calc(100% - 90px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: grab;
}

.removalSpinner {
  width: 100%;
  height: 100%;
  background-color: rgba(lightgray, .5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.contentIcon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.isFullScreen {
  font-size: 14px;
  line-height: 20px;
  margin: 0 16px;
}

.name {
  width: 0; // hack to not stretch the PlaylistItem if the name is very long
  flex: 1;
  font-size: 14px;
  line-height: normal;
  color: #6A6A6A;
  margin-right: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.clockIcon {
  margin-left: 16px;
}

.duration {
  font-size: 14px;
  line-height: 20px;
  color: #A0A0A0;
  margin: 0 16px;
}

.menu {
  display: none;
  flex-wrap: nowrap;
  transition: opacity .3s ease;

  &Button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &Delete {
      background-image: url(../../../../../../assets/images/icons/delete-icon.svg);

      &:hover {
        background-image: url(../../../../../../assets/images/icons/delete-icon-gray.svg)
      }
    }

    &Edit {
      background-image: url(../../../../../../assets/images/icons/edit-dark.svg);

      &:hover {
        background-image: url(../../../../../../assets/images/icons/edit-gray.svg)
      }
    }

    &:first-child {
      margin-right: 16px;
    }
  }
}

.settings {
  display: flex;
}