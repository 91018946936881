.container{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  border-bottom: 1px solid #EFEFF4;
  color: #8A8A8F;
}

.dragHandle {
  width: calc(100% - 90px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: grab;
}

.disableDrag {
  cursor: not-allowed;
}

.editBtn {
  background-image: url(../../../../../assets/images/icons/edit-gray.svg);

  &:hover {
    background-image: url(../../../../../assets/images/icons/edit-dark.svg);
  }
}

.deleteBtn {
  background-image: url(../../../../../assets/images/icons/delete-icon-gray.svg);

  &:hover {
    background-image: url(../../../../../assets/images/icons/delete-icon.svg);
  }
}

.innerContent {
  flex: 1 1 auto;
  word-break: break-all;
}

.btnGroup {
  flex: 0 0 70px;
}