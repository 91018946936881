@import 'src/variables.scss';

.container {
  display: flex;
  height: 100%;
  align-items: stretch;
  padding: 20px;
}

.screensList {
  width: 100%;
  flex: 1 1 40%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 16px;
  background: white;
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
  border-radius: 3px;
}

.screenDetails {
  flex: 1 1 60%;
  height: 100%;
  margin-left: 24px;
  background: white;
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
  border-radius: 3px;
  position: relative;
}

.containerCenter {
  background:white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  flex: 0 1 550px;
  min-width: 300px;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
}

.details {
  background:white;
  // background-image: url('../../../../../assets/images/empty-background.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #CDCDCD;
  display: flex;
  margin: 20px 20px 20px 0;
  justify-content: center;
  align-items: center;
  flex: 0 1 550px;
  min-width: 485px;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
}

.emptyScreensArea {
  background:white;
  background-image: url('../../../../../assets/images/empty-background.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #CDCDCD;
  display: flex;
  // margin: 20px 20px 20px 0;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  // min-width: 470px;
  // min-height: 550px;
  // box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  // border-radius: 3px;
}

.detailedContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height:100%;
  clear: both;
  // background-color: blue;
}

.formContainer {
  margin: 0;
  padding: 0;
}

.buttonContainer {
  // margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.playlistContainer {
  display: flex;
  align-items: center;
  // background-color: #aaaaaa;
  margin: 0px 20px;
  padding: 0;
  min-height: 3em;
}

.playlistsFieldArrayContainer{
  flex-grow: 1;
  display: flex;
  margin: 0 20px 20px 20px;
  border: 1px solid #E9E9E9;
  border-radius: 3px;
  overflow-y: auto;
  // background-color: red;
}
.addContentAreaContainer{
  flex-grow: 1;
  display: flex;
  margin: 20px;
  border: 1px solid #E9E9E9;
  border-radius: 3px;
  clear: both;
  // background-color: red;

  // overflow: scroll;
  // overflow-y: auto;
  // background-color: red;
}

.detailsActive {
  background:white;
  display: flex;
  margin: 20px 20px 20px 0;
  justify-content: top;
  align-items: top;
  flex: 0 1 550px;
  min-width: 485px;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
}


.contentListEmpty {
  background-image: url('../../../../../assets/images/empty-background.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #CDCDCD;
  margin-bottom: 45px;
  display: flex;
  max-height: 80%;
  height: 300px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screensContainer {
  display: flex;
  flex:1 45px;
  flex-direction: column;
  min-height: 50%;
  overflow-y: auto;
  margin:0 50px 0 50px;
}

.list-item {
  font-size: 5px;
  color: gray;
  font-weight:bold;
  font-size: 15px;
}

.listItem {
  flex:1 45px;
  min-width: 280px;
  max-width: 100%;
  min-height: 45px;
  background-color: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;

  color: #6A6A6A;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: center;
  padding-left:20px;
  align-items: center;
  margin-bottom: 10px;

}

.listItem:hover{
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.activeScreensItem {
  flex:1 45px;
  min-width: 280px;
  min-height: 45px;
  background-color: $primaryColor;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  padding-left: 20px;
  color: white;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

h1 {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  font-size: 20px;
  text-align: center;

  color: #000000;
}

p {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  color: #AAAAAA;
}

.buttonStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  margin: 0 auto;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 40px;
 }

.buttonStyleDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  margin: 0 auto;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
  cursor: not-allowed;
 }

.iconPosition {
  margin-left: -5px;
  margin-right: 5px;
}



.centeredEmtyScreenText {
  display: block;
  align-content: center;
  width: 100%;
  color:#aaaaaa;
  margin-top: 20px;

}

p{
  text-align: center;
}

.textColor{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  text-align: center;

  color: #8A8A8F;
}

.arrowPosition {
  margin-left:20px;
}

ul, li {
  margin: 0;
  padding: 0;
}

.addScreenContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
}

.btnGroup {
  display: flex;
  padding: 0 20px;
}

.titleSelectSize {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  font-size: 20px;

  color: #000000;
  padding: 20px 20px 0 20px;
}

.notificationChangeSize {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  margin-top: 8px;

  img {
    margin-right: 8px;
  }
}

.closeButton {
  position: absolute;
  top: 28px;
  right: 20px;
  width: 25px;
  height: 25px;
  background-image: url('../../../../../assets/images/icons/close.svg');
  background-size: contain;
  background-color: transparent;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 4px;
  z-index: 10;
}

.anotherCloseButton {
  position: absolute;
  top: 28px;
  right: 50px;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 4px;
  z-index: 10;
}

.wrapper {
  display: flex;
  width: 100%;
}

hr.screenSearchHr {
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.inputSearch {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 8px 42px 8px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #000000;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  // margin-bottom: 16px;
  text-decoration: none;
  margin: 8px;

  &:hover {
    color: #000;
    text-decoration: none;
  }

  &IsActive {
    border-color: $primaryColor;
  }
}