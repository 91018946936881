@import 'src/variables.scss';

.size {
  &Header {
    display: flex;
    justify-content: space-between;
  }

  &Reset {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #EC812B;
  }

  &Control {
    width: 100%;
    margin-top: 18px;
    margin-right: 18px;
  }

  &Label {
    min-width: 102px;
    margin-right: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
}

.rangeRoot {
  position: relative;
}

.rangeContainer {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 100;
}

.handle {
  border: 1px solid red;
}

.display {
  width: 100%;
  display: flex;
  background-color: #F7F7FA;
  position: relative;

  &Item {
    background-color: #fff;
    text-align: center;
    position: relative;
    z-index: 2;

    .box {
      width: 100%;
      padding: 4px 0;
      font-size: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .percent {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      font-size: 12px;
      line-height: 20px;
      color: #8A8A8F;
    }

    &.insidePlaylist .box {
      background-color: #FBE6D5;
      color: #EC812B;
    }

    &.bottomBar .box {
      background-color: #F7F7FA;
      color: #8A8A8F;
    }
  }

  .bottomLine {
    width: 100%;
    height: 20px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
