@import 'src/variables.scss';

.container {
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 0;
}

.body {
  flex-grow: 1;
  // flex: 1 1 100%;
  display: flex;
  padding-top: 24px;
  flex-direction: column;
}

.playlistBoxes {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 0;
}

.bottomBar {
  flex: none;
  padding: 18px;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  color: #666666;
  margin-top: 8px;
}
