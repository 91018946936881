@import 'src/variables.scss';

.container {
  display: flex;
  width: 100%;
  height: 62px;
  background: #FFFFFF;
  border-radius: 2px;
  margin-bottom: 8px;
  position: relative;
}

.sizeCard {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 122px;
  padding: 0 12px;
  border: 1px solid #EFEFF4;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &Icon {
    margin-bottom: 6px;
  }

  &Text {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #8A8A8F;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  width: 0;
  flex: 1;
  max-width: calc(100% - 122px);
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 0 21px;
  border: 1px solid #EFEFF4;
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  &Icon {
    margin-right: 16px;
  }
}

.text {
  flex: 1 1;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete,
.edit {
  margin-left: 16px;

  &:hover {
    opacity: 0.7;
  }
}

.edit {
  display: flex;
  align-items: center;
  color: $primaryColor;

  span {
    margin-left: 8px;
  }
}
