.programmesList {
  display: flex;
  flex: 1 1 40%;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
  border-radius: 3px;
  padding: 24px;
  align-items: stretch;
  overflow: auto;
}

.footer {
  display: flex;
}
