.underlay {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #DCDCDC;
  opacity: 0.5;
}

.modalContainer {
  z-index: 1000;
  padding-top: 80px;
  padding-bottom: 80px;
}

.modal {
  padding: 0;
  width: 100%;
  max-width: 740px;
  // flex-direction: column;
  // min-width: 500px;
  // padding: 24px;
  // border-radius: 3px;
  // background-color: white;
  // box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  // z-index: 1000;
}

.modalHeader {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  font-size: 20px;
  color: #000000;
  position: relative;
}

.modalCloseBtn {
  width: 25px;
  height: 25px;
  background-image: url('../../../../../../assets/images/icons/close.svg');
  background-size: contain;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.modalBody {
  flex-grow: 1;
  padding: 24px;
}
