.container {
  padding: 0;
  display: flex;
}

.formStyle {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  .contentWrapper {
    border-radius: 3px;

  }
}

.formGroupBlock {
  border: 1px solid #EFEFF4;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // background-color: blue;

}

.tabStyles{
  // margin: 0;
  // width: 400px;
  // background-color: red;
  display: flex;
  flex-direction: row;
}

.formGroupWrapper {
  display: flex;
  justify-content: space-between;
}

.formBlockStyleDuration {
  margin-top: 20px;
}
.formBlockStyleSize {
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // flex-direction: column;
  // justify-content: flex-start;
  background-color: red;
}

.buttonStyleLeft {
  width: 50%;
  margin: 0;
  height: 50px;
  background: rgba(164, 164, 164, 0.05);
  color: #C6C6C6;
  border-radius: 3px 0px 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  cursor: pointer;
}

.buttonStyleLeftActive {
  width: 50%;
  margin: 0;
  height: 50px;
  color: #000;
  background: #fff;
  border-radius: 3px 0px 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;
}

.buttonStyleRight {
  width: 50%;
  margin: 0px;
  height: 50px;
  background: rgba(164, 164, 164, 0.05);
  color: #C6C6C6;
  border-radius: 0px 3px 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;
}

.buttonStyleRightActive {
  width: 50%;
  margin: 0px;
  height: 50px;
  background: #fff;
  color:black;
  border-radius: 0px 3px 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: left;
}

.buttonStyle {
  width: 130px;
  height: 40px;
  background: #078AE2;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  margin-left: 0;
  color: white;
}
.buttonStyleCancel {
  width: 103px;
  height: 40px;
  background-color:#fff;
  color:#078AE2;
  border-color: #078AE2;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  margin-left: 0;
}

.labelButtonStyleCancelActive {
  width: 156px;
  height: 40px;
  background-color:#fff;
  color:#078AE2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #078AE2;
  border-radius: 3px;
  cursor: pointer;
}

.labelButtonStyleCancel {
  width: 156px;
  height: 40px;
  background-color:#fff;
  color:#C8C7CC;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #C8C7CC;
  border-radius: 3px;
  cursor: pointer;
}

.optionsStyles {
  width: 80px;
  height: 40px;
  padding: 5px 40px 5px 5px;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  overflow: hidden;
}

.optionsStylesToggled {
  width: 80px;
  height: 40px;
  padding: 5px 40px 5px 5px;
  border: 1px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
}

.optionsStyles:focus {
  border: 1px solid #078AE2;
  padding: 5px 40px 5px 5px;
}

.labelDuration {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
}

.imgSize {
  width: 24px;
  height: auto;
}

.groupStyleRow {
  display: flex;
  flex-direction: row;
}

.inputWrapper {
  flex: 1;
}
.inputWrapperDuration {
  width: 90px;
}

.dataFileWrapper {
  border-bottom: 1px solid #EFEFF4;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 16px;
  height: 50px;
}

.dataFileContainer {
  min-width: 210px;
  line-height: 50px;
  text-align: left;
}

.dataBlock {
  justify-content: space-between;
  line-height: 50px;
  text-align: left;
  margin-right: 16px;
}

.childDropZone {
  flex: 1;
  margin-bottom: 24px;
}

.optionsStylesToggled {
  width: 80px;
  height: 40px;
  padding: 5px;
  border: 1px solid #fff;
  box-sizing: border-box;
}

.dropZoneWrapper {
  flex: 1;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
  // margin: 24px 17px 0px 17px;
}

.textBlock {
  display: flex;
  flex-direction: row;
  line-height: 40px;
}
.textBlock span {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  padding: 0 5px;
  color: #000000;
}

.inputStyle {
  position: relative;
}

.unitMeasure {
  position: absolute;
}