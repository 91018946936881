@import 'src/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 24px;
  outline: none;
  user-select: none;
  overflow: auto;
  position: relative;
}

.dropZoneOverlay {
  display: none;
  width: 100%;
  height: 100%;
  border: 1px dashed $primaryColor;
  background-color: rgba($primaryColor, 0.7);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  &IsVisible {
    display: block;
  }
}

.openMediaLibraryBtn {
  padding: 0 20px;
  margin-bottom: 20px!important;
}

.screenInfo {
  flex: none;
  display: flex;
  padding: 20px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid #EFEFF4;
}

.footer {
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleSelectSize {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  font-size: 20px;
  color: #000000;
  padding-bottom: 18px;
}

.notificationChangeSize {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  margin-top: 8px;

  img {
    margin-right: 8px;
  }
}

.playlistsFieldArrayContainer{
  flex-grow: 1;
  display: flex;
  margin: 0 20px 20px 20px;
  border-radius: 3px;
  overflow-y: auto;
  position: relative;
}

.titlePlaylist {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 3em;
  font-size: 14px;
  color: #000000;
  padding-top: 20px;
  padding-left: 20px
}