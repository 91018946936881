
.request {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  color: #000;
  font-style: normal;
  font-weight: normal;
  border-bottom: 1px solid #EFEFF4;

  &:last-child {
    border-bottom: 0;
  }
}

.requestTime {
  margin-right: 12px;
  font-size: 12px;
  color: #666;
}

.requestUrl {
  flex: 1;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.requestStatus {
  margin: 0 10px;
  font-size: 14px;
  min-width: 104px;
}

.requestMeta {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  color:#8A8A8F;
  font-size: 12px;
  min-width: 125px;
}

.took {
  margin-bottom: 2px;
}

.recUpdated {

}
