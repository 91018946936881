@import 'src/variables.scss';

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.containerWrapper{
  display: flex;
  align-items: top;
  height: 100%;
  min-height: 780px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin: 20px;
  flex: 1 1 50%;
  min-width: 300px;
  background:white;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  padding-top: 40px;
}

.playlistItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  min-width: 300px;
  margin: 20px;
  padding: 24px;
  background:white;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  overflow-y: auto;
}

.playlistItemsList {
  width: 100%;
  padding-right: 8px;
  overflow-y: scroll;
}

.labelName {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 3em;
  font-size: 14px;
  color: #000000;
}

.groupStyleRow {
  display: flex;
  flex-direction: row;
  height: 70px;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 20px;
}

.containerCenter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  flex: 1 1 50%;
  min-width: 300px;
  background:white;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
}

.details {
  display: flex;
  flex-direction: column;
  background:white;
  background-repeat: no-repeat;
  background-position: center;
  color: #CDCDCD;
  margin: 20px;
  margin-left: 0;
  flex: 0 1 60%;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  position: relative;
}
.emptyPlaylistArea {
  background:white;
  background-image: url('../../../../../assets/images/empty-background.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #CDCDCD;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}

.detailsActive {
  display: flex;
  flex-direction: column;
  background:white;
  background-repeat: no-repeat;
  background-position: center;
  color: #CDCDCD;
  margin: 20px;
  margin-left: 0;
  flex: 1 1 60%;
  box-shadow: 0px 4px 15px rgba(156, 156, 156, 0.25);
  border-radius: 3px;
  position: relative;
}

.contentList {
  margin-bottom: 45px;
  display: flex;
  max-height: 80%;
}

.contentListEmpty {
  background-image: url('../../../../../assets/images/empty-background.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #CDCDCD;
  display: flex;
  width: 248px;
  height: 234px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconPosition {
  margin-left: -5px;
  margin-right: 5px;
}

.screensContainer {
  display: flex;
  flex:1 45px;
  flex-direction: column;
  min-height: 50%;
  overflow-y: auto;
  margin:0 50px 0 50px;
  padding-right: 15px;
}



ul, li {
  margin: 0;
  padding: 0;
}

.listItem {
  flex: 1 45px;
  min-width: 280px;
  max-width: 100%;
  min-height: 45px;
  background-color: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;

  color: #6A6A6A;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: center;
  padding-left:20px;
  align-items: center;
  margin-bottom: 10px;

}

.activeScreensItem {
  flex:1 45px;
  min-width: 280px;
  max-width: 100%;
  min-height: 45px;
  background-color: $primaryColor;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  padding-left: 20px;
  color: white;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.listContainerWraper{
  flex-basis: 300px;
  min-width: 300px;
  padding: 10px;
  background-color: rgb(239, 239, 250);
}

.listContainer{
  border: 1px rgb(134, 134, 134) solid;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px;
}

.contentContainer{
  min-width: 1000px;
  flex-basis: 1400px;
  padding: 10px;
  background-color: rgb(239, 239, 250);
}

.buttonStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  margin: 0 auto;
  clear: both;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 40px;
 }

.buttonStyle:hover {
  text-decoration: none;
}

 .buttonStyleDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  margin: 0 auto;
  clear: both;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #FFFFFF;
  cursor:not-allowed;
}

.listItem {
  flex:1 45px;
  min-width: 280px;
  max-width: 100%;
  height: 45px;
  background-color: #fff;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;

  color: #6A6A6A;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: center;
  padding-left:20px;
  align-items: center;
  margin-bottom: 10px;

}

.listItem:hover{
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.activeScreensItem {
  flex:1 45px;
  min-width: 200px;
  height: 45px;
  background-color: $primaryColor;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  padding-left: 20px;
  color: white;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.arrowPosition {
  width: 30px;
}

.createNewPlaylistBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  margin: 0 auto;
  background: $primaryColor;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.centeredEmtyScreenText {
  display: block;
  align-content: center;
  width: 100%;
  color:#aaaaaa;
}

p{
  text-align: center;
}
.textColor{
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: #8A8A8F;
}

.closeButton {
  position: absolute;
  top: 28px;
  right: 20px;
  width: 25px;
  height: 25px;
  background-image: url('../../../../../assets/images/icons/close.svg');
  background-size: contain;
  background-color: transparent;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 4px;
  z-index: 10;
}

.wrapper {
  display: flex;
}

.inputSearch {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 8px 42px 8px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #000000;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  // margin-bottom: 16px;
  text-decoration: none;

  &:hover {
    color: #000;
    text-decoration: none;
  }

  &IsActive {
    border-color: $primaryColor;
  }
}