@import 'src/variables.scss';

.container {
  width: 100%;
}

.drop_area {
  width:100%;
  height: 180px;
  padding: 2px;
  border: 2px gray;
  border-style: dashed;
  border-radius: 5px;
  cursor: pointer;
}

.zmdi-close{
  cursor: pointer;
}

.form_group_block {
  margin-top:5px;
  border-radius: 1px;
  padding: 0 5px;
}

.form_group_block_with_overflow {
  border-radius: 5px;
  border: 1px solid rgb(162, 161, 161);
  padding: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100px;
  background-color: rgb(248, 248, 248);
}

.dateContainer {
  margin: 0px auto 24px auto;
  display: flex;
  flex-flow: row nowrap;
  position: relative;

  .dataAndPeriod {
    border: 1px solid #EFEFF4;
    text-align: center;
    width: 80px;
    height: 40px;
    color: black;
  }

  .dataAndPeriodToggled {
    border: 1px solid #fff;
    text-align: center;
    width: 80px;
    height: 40px;
    color: black;
  }

  .timeLine {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 40px;
    margin: 0 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }

  .selectDataLabel {
    width: 30px;
    height: 40px;
    border: 1px solid #EFEFF4;
    border-left: 0px;
    box-sizing: border-box;
    border-radius: 0px 3px 3px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    cursor: pointer;
    margin-right: 5px;
    text-align: center;
    color: $primaryColor;
  }
}

.existing_playlist {
  display: block;
  line-height: 0.2;
  margin-top: 1px;
  cursor:pointer;
}

.video_size_and_position {
  width: auto;
  height:100%;
  position: absolute;
  left: 50%;
  top:0;
  transform: translate(-50%,0);
}

.centered_position {
  position: absolute;
  left:50%;
  transform: translate(-50%, 0);
}

.dropTextPosition {
  text-align: center;
  padding-top: 80px;
}

.selectDataLabelDelete {
  width: 70px;
  height: 20px;

  padding-top: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 0;
  cursor: pointer;
  margin-right: 5px;
  margin-top:5px;
  text-align: center;
  color: $primaryColor;
}

.inputStyleCursor {
  cursor: pointer;
}

.optionsStyles {
  width: 100px;
  height: 1em;
}

.timeLabelStyle {
  font-size: 14px;
  text-transform: uppercase;

  color: #000000;
  margin-top:22px;
}

.addTimeRestriction {
  margin-top: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  cursor: pointer;
  color: #A0A0A0;
}

.iconStyle {
  margin-right: 20px;
  padding: 0;
}

.selectData {
  flex: 1;
  height: 40px;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  z-index: 2000;
  color: #000000;
  padding: 10px;
  cursor: pointer;
  color: #000000;
  outline: none;
  margin-left: 16px;
}

.selectDataToggled {
  flex: 1;
  height: 40px;
  border: 1px solid #fff;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  z-index: 2000;
  color: #000000;
  padding: 10px;
  cursor: pointer;
  color: #000000;
  outline: none;
  margin-left: 16px;
}
.deleteButton{
  height: 40px;
  width: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: -1px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}