@import 'src/variables.scss';

.root {
  display: flex;
}

.error,
.warning {
  color: red;
}
