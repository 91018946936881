@import 'src/variables.scss';

.container {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.body {
  border-radius: 3px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  padding-bottom: 24px;
  border: 1px solid #EFEFF4;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  &IsHtml {
    border-bottom: 0;
  }
}

.filterBtn {
  color: #8a8a8f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  flex: auto;
  cursor: pointer;
}

.filterBtnActive {
  color: #000000;
  border-bottom: 1px solid $primaryColor;
}

.dropZone {
  padding: 16px;
  margin-top: 24px;
  border: 1px dashed #C8C7CC;
  box-sizing: border-box;
  border-radius: 3px;
}

.dropZoneText {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;

  span {
    color: $primaryColor;
    cursor: pointer;
  }
}

.title {
  text-align: center;
  cursor: default;
  margin-bottom: 12px;
}

.openButton {
  cursor: pointer;
  color: $primaryColor;
}

.contentContainer {
  flex-grow: 1;
  border: 1px solid #EFEFF4;
  border-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  overflow-y: auto;
  max-height: 45vh;
  width: 100%;
  // max-width: 460px;
  // max-height: 53vh;
}

.checkmark {
  position: relative;
  // position: absolute;
  // top: 14px;
  // left: 28px;
  margin: 0 28px 0 26px;
  flex: none;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 2px solid #C8C7CC;
  border-radius: 50%;
  cursor: pointer;
  z-index: -200;

  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid $primaryColor;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contentItem {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #EFEFF4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // padding-left: 56px;
  padding-right: 24px;
  cursor: pointer;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

    input ~ .checkmark {
      background-color: #fff;
    }

    .contentItemAddBtn {
      opacity: 1;
      display: flex;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #fff;
      border: 2px solid $primaryColor;
      color: $primaryColor;

      &:after {
        display: block;
      }
    }
  }

  &AddBtn {
    display: none;
    align-items: center;
    color: $primaryColor;
    font-size: 14px;
    // position: absolute;
    // top: 50%;
    // right: 24px;
    // transform: translateY(-50%);
    opacity: 0;
    transition: opacity .3s ease;
  }
}

.contentItemName {
  width: 90%;
  // margin-left: 28px;
  z-index: -200;
  display: flex;
  overflow: hidden;
  height: 100%;
  align-items: center;
}

.contentItemNameWrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemImageBlock {
  margin-top:2px;
  width: 30px;
}

.iconPosition {
  flex: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 20px;
  margin-top: -2px;
}

.trashBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-left: 0;
  box-sizing: border-box;
  mix-blend-mode: normal;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  border-radius: 3px;
  float: left;

  &:hover {
    opacity: 0.9;
  }

  .icon {
    width: 16px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url(../../../../../../../assets/images/icons/trash.svg);
    background-size: contain;
  }
}

.addNewMessageBtnContainer {
  padding-left: 16px;
  padding-bottom: 20px;
  border: 1px solid #EFEFF4;
  border-top: 0;
}

.addNewMessageBtn {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 20px;
  border: 1px solid #C8C7CC;
  box-sizing: border-box;
  border-radius: 3px;
}

.confirmBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  margin-left: auto;
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 3px;
  mix-blend-mode: normal;
  color:white;
  background-color: $primaryColor;
  float:left;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.8;
  }
}

.dropZoneMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 1px solid #f1f1f1;
  border-top: none;
}