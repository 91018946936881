.container {
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  background-color: #ffffff;
  padding: 24px;
  box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.1);
  position: relative;

  & > button {
    align-self: center;
    width: 100%;
    max-width: 165px;
  }
}

.addSessionButton {
  margin-top: 56px;
}

.closeButton {
  position: absolute;
  top: 28px;
  right: 20px;
  width: 25px;
  height: 25px;
  background-image: url('../../../../../assets/images/icons/close.svg');
  background-size: contain;
  background-color: transparent;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 4px;
  z-index: 10;
}