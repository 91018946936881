@import 'src/variables.scss';

.screenSizeForm {
  flex-direction: column;
  padding-bottom: 20px;
}

.screenSizeLabel {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 3em;
  font-size: 14px;
  color: #000000;
}

.titleSelectSize {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  font-size: 20px;
  color: #000000;
  padding-bottom: 18px;
}

.notificationChangeSize {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  margin-top: 8px;

  padding-bottom: 32px;
  border-bottom: 1px solid #EFEFF4;

  img {
    margin-right: 8px;
  }
}

.orientationBox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-flow: row wrap;
  overflow: auto;
  margin: -5px;
}

.orientationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 104px;
  background: white;
  padding: 10px;
  border: 1px solid #C8C7CC;
  color: #8A8A8F;
  border-radius: 2px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  color: #8A8A8F;
  position: relative;
  transition: border-color .3s ease;
  margin: 5px;

  &:hover {
    color: $primaryColor;
    border-color: $primaryColor;

    & .closePosition {
      display: initial;
    }
  }

  &TextContainer {
    display: flex;
    flex-direction: column;
    line-height: 14px;
  }

  &Title {
    font-size: 14px;
  }

  &Subtitle {
    font-size: 12px;
  }

  &IsSelected {
    border-color: $primaryColor;
    color: $primaryColor;
  }
}

.editIcon {
  opacity: 0;
  transition: opacity .3 ease;
  position: absolute;
  top: 5px;
  right: 5px;
  transition: opacity .3s ease;
}

.closePosition{
  display: none;
  position: absolute;
  right: 8px;
  top: 5px;
  cursor: pointer;
}

.customSizeBtn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90px;
  height: 90px;
  color: $primaryColor;
  background: #FFFFFF;
  border: 1px solid $primaryColor;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  margin-top: 16px;
}

.wrapFormCustomSize {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 16px;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
}

.sizeNameBlock {
  width: 100%;
  padding: 8px 0;
}

.resolutionBlock {
  width: 100%;
  padding: 8px 0;
}

.btnGroup {
  width: 100%;
  display: flex;
  margin-top: 24px;

  button + button {
    margin-left: 16px;
  }
}

.customText {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
  margin-right: 12px;
}

.sizeNameField {
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  box-sizing: border-box;
  width: calc(100% - 80px);
  padding: 10px 16px;
}

.resolutionField {
  background: #FFFFFF;
  border: 1px solid #EFEFF4;
  padding: 10px 16px;
  box-sizing: border-box;
  max-width: 84px;
}

.resolutionField:last-child {
  margin-left: 23px;
}

.customSizeWrapper {
  margin-top: 30px;
}

.titleText {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;

  color: #000000;
}

.screenSizeStyle {
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  display: block;
  // background-color:red;
  margin-top:4px;
  // color: $primaryColor;
}
.screenSizeStyleDim {
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  display: block;
  // background-color:red;
  // margin-top:4px;
  // color: $primaryColor;
}



