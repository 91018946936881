.modalContainer {
  z-index: 1000;
  display: flex;
}

.modal {
  margin: auto;
  min-width: 0;
  // max-width: 508px;
  padding: 0;
}

.container {
  border-radius: 3px;
  width: 557px;
  z-index: 9000px;
  box-shadow: 5px 4px 16px rgba(0, 0, 0, 0.15);
  user-select: none;
}

.modalbodyStyle{
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 5px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.headerSyle {
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  font-size: 20px;

  color: #000000;
}

.formGroupWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.buttonStyle {
  width: 102px;
  height: 40px;
  background: #078AE2;
  mix-blend-mode: normal;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  margin-left: 0px;
  color: white;
}

.headerContentSettings {
  display: block;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  font-size: 20px;

  color: #000000;
}

.backgroundOfModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}