@import 'src/variables.scss';

.container {
  width: 141px;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  color: #000000;
  border: 1px solid #EFEFF4;
  border-radius: 3px;
  text-decoration: none;

  &:hover {
    color: #000;
    text-decoration: none;
  }
}
