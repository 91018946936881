.froala-no-fullscreen {
  .fr-box {
    width: 801px!important;
    height: 534px;
    background-color: #000!important;
    position: relative!important;
    margin-top: 38px;
  }

  .fr-box.full-screen {
    width: 100%!important;
    height: 100%!important;
    position: fixed!important;
  }

  .fr-wrapper {
    transform-origin: top left;
    position: absolute!important;
    top: 50%;
    left: 50%;
  }

  /* .fr-wrapper.full-screen {
    width: 100%!important;
    max-width: 100%!important;
  } */

  .fr-wrapper iframe body {
    margin: 0;
    padding: 0;
  }

  .fr-toolbar {
    transform: translateY(-100%);
    padding: 0 !important;
  }

  .fr-toolbar button {
    margin: 0 !important;
  }

  .CodeMirror {
    height: 100%;
  }
}

.froala-popup-hidden {
  .fr-popup.fr-active {
    display: none;
  }
}

.fr-fullscreen-wrapper {
  .CodeMirror {
    height: 100%;
  }
}

// .fr-view {
//   font-size: 150px;
// }

.fr-view {
  font-family: "Open Sans", sans-serif;
  // line-height: 1.25em!important;
  // font-size: 150px;
}