@import "src/variables";

.container {
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;

  &Medium {
    height: 30px;
    padding: 0 12px;
  }
}

.tip {
  margin-top: 20px!important;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6A6A6A;
}