@import 'src/variables.scss';

.navigationTabs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 0 55px;
  margin-bottom: 24px;
}

.tab {
  padding: 16px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #8A8A8F;
  cursor: pointer;

  &.isActive {
    color: #000000;
    border-bottom: 1px solid $primaryColor;
  }
}