@import 'src/variables.scss';

.container {
  display: flex;
  padding: 12px 10px;
  align-items: center;
  border-bottom: 1px solid #EFEFF4;
  user-select: none;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #EEEEEE;
  margin-right: 15px;
  position: relative;

  &CheckMark {
    display: none;
    width: 5px;
    height: 10px;
    border: solid $primaryColor;
    border-width: 0 3px 3px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &IsChecked {
    border-color: $primaryColor;

    .checkboxCheckMark {
      display: block;
    }
  }
}

.content {
  width: 0;
  flex: 1;
  display: flex;
  align-items: center;
}

.name {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #6A6A6A;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  width: 24px;
  margin-right: 12px;
}
